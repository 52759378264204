export const formatMoney = (value: string | number, overrideOpts: { digit?: number } = {}) => {
  const options = { currency: 'TWD', ...overrideOpts }

  if (options.currency === 'TWD' && options.digit === undefined) {
    options.digit = 0
  }

  const moneyFormatter = new Intl.NumberFormat('zh-TW', {
    currency: options.currency,
    maximumFractionDigits: options.digit ?? 0,
    minimumFractionDigits: options.digit ?? 0,
    style: 'currency',
  })

  return 'NT' + moneyFormatter.format(+value)
}
